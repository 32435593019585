body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ql-tooltip {
  z-index: 1;
  transform: translateX(148px) translateY(10px) !important;
}

.ql-tooltip::before {
  content: 'Wprowadź URL:' !important;
}

/*.ql-editor {*/
/*  padding: 0 !important;*/
/*  border: none;*/
/*}*/

.ql-container {
  border: none !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
